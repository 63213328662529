.balance-header{
    background: #e9e9e9;
    z-index: 0;
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
    border-bottom: 1px solid #002f7c;
}

.transacciones > div:nth-child(1){
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
    align-items: center;
}

.transacciones .transaccion:nth-child(odd){
    background: #012156;
    padding: 20px;
}

.transacciones .transaccion{
    padding: 20px;
}

.transacciones .transaccion > div{
    display: flex;
    justify-content: space-between;
}

.transacciones .transaccion > div div > span{
    font-size: 0.9rem;
    color: #b3adad;
    font-weight: 100;
} 

.transacciones .transaccion div > span{
    font-weight: 600;
}

.transacciones h5 {
    margin: 0;
}

.wordbreak{
    word-break: break-all !important; 
}