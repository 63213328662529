.freeze-footer{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}

.footer-opciones > div:last-child {
    border-right: 0px solid white !important;
}


.footer-opciones p{
    font-size: 13px;
    margin-bottom: 0rem;
}

.footer-opciones i{
    font-size: 20px;
}
