
.container-send{
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}



  @media (max-width: 463px) {
    .Box-send{
        width: 25vh;
        height: 25vh;
        background: linear-gradient(180deg, rgba(63,27,131,1) 35%, rgba(35,14,106,1) 90%);
        border-radius: 15%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 17px rgb(0 0 0 / 37%);
    }
}