.scanner {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.scanner video{
    transform: scaleX(1) !important;
    width: auto !important;

}

.scanner > div {
    overflow: hidden;
    position: relative;
}


.icon-x i{
    font-size: 5rem;
}

.show {
    display: block;
}

.hidden {
    display: none;
}

.scan-code {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 200px;
    height: 200px;
  }
  

@media (max-width: 515px) {
    .scanner > div,
    .scanner video {
        width: 100% !important;
    }
    .mb-5{
        margin-bottom: 1rem!important;
    }
    /* .form-select {
        margin-left: 0px;
    } */
}

@media (max-width: 400px) { 
    .icon-x {
        width: 280px;
        height: 180px;
    }
}