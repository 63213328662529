.max-width-form label {
  color: rgb(120, 120, 120);
}

.MuiInputBase-root{
  color: white !important;
}

.MuiFormLabel-root{
  color: white !important;
}

.div-props{
  display: flex;
    align-content: flex-start;
    align-items: baseline;
    justify-content: space-around;
}

.form-inline{
  display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.wrong-network {
  z-index: 1250;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  background-color: #ff9800;
  padding: 5px;
}

.create-nft{
  width: 95%;
    border-radius: 10px !important;
    padding: 0.2rem 1rem;
    color: white;
    background-color: #0E56CC;
    font-weight: bold !important;
    font-size: 15px !important;
    font-family: 'Roboto' !important;
    height: 2rem;
    margin-top: 1rem;
    border-color: transparent !important;
}

.create-nft:disabled{
    background: linear-gradient(180deg, rgba(14,86,204,1) 0%, rgb(138, 138, 138) 50%, rgba(70,182,217,1) 100%) !important;
    box-shadow: 2px 1000px 1px #002F7C inset !important;
   
}