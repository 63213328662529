.title-nft{
    text-align: center;
    font-size: 1rem;
}

.img-container{
    overflow: hidden;
    border-radius: 15px;
    max-width: 87%;
}



.tab-div{
    width: 75%;
}

.address-wrapper{
    display: flex;
    flex-direction: row;
    border: 1px solid white;
    border-radius: 15px;
    width: 85%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: white;
    height: 40px;
    align-items: center;
    justify-content: center;
}
.transferirNft {
    width: 85%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    margin: auto;
}


.input-address{
    background-color: transparent;
    border: none !important;
    width: 85%;
    color: white; 
    padding: 0 10px 0 0px;
}

.input-address:focus{
    color: white;
    outline: none;
}

.input-address::placeholder{
    color: rgb(171, 171, 171);
    border: none;
}

.icon-camera{
   width: 25px;
   height: 25px;
}

.description-wrapper{
    width: 85%;
    margin-top: 1rem;
    
}

.scanner-send{
    width: 320px !important;
}

.titulo-propiedades{
    text-align: center;
    font-weight: 600;
    font-size: 20px;
}

.box-propiedades{
    text-align: center;
    font-size: 14px;
}

