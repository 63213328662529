@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

html, body {
  background-color: #002F7C !important;
  color: white !important;
}

a, a:hover {
  text-decoration: none !important;
  color: inherit;
}

h5 {
  font-family: 'Roboto Condensed', sans-serif;
}

.max-width-form{
  max-width: 30rem;
  margin: auto;
  width: 100%;
}

.container-desktop{
  max-width: 900px;
  margin: auto;
}

.centrar-todo{
  
  display: flex;
  flex-direction: column;
}

.centrar-componente{
  height: 69.7vh;
  display: flex;
  align-items: center;
  justify-content: center;
}



.titleh5{
  margin: 20px 0 10px;
  text-align: center;
}

.invalid-feedback{
  text-align: start;
}

@media (min-width: 992px) {
  /* .container-smaller{
    max-width: 800px !important;
  } */
  .in-the-middle{
    position: absolute;
    left: 0;
    right: 0;
    top: 20%;
  }

  .min-height-margin{
    min-height: calc(100vh - 176px);
    margin-bottom: 176px;
  }
}

@media (max-width: 991px) {
  .in-the-middle{
    margin-top: 2rem;
  }

  .min-height-margin{
    min-height: calc(100vh - 66px);
    margin-bottom: 66px;
  }
}

@media (max-width: 500px) {
  .titleh5 {
    text-align: left;
    margin: 20px 0 10px 15px;
  }
}

@media only screen and (orientation:landscape) and (max-width:991px) {

  .centrar-todo{
    height: auto;
  }

}

.session-card {
  border: 1px solid rgb(255,255,255,0.15);
}