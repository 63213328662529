.title-nft{
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
}

.img-container{
    overflow: hidden;
    border-radius: 15px;
}

.img-container > div {
    width: 100%;
}

.img-container > div > svg {
    width: 1000px;
}



.send-nft {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0E56CC;
    border: none;
    width: 100%;
    font-weight: bold;
    font-size: 15px;
   
}

.send-nft:disabled {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #516078 !important;
    border: none;
    width: 100%;
    font-weight: bold;
    font-size: 15px;
   
}

.send-nft:hover {
box-shadow: none;
}

.address-wrapper{
    display: flex;
    flex-direction: row;
    border: 1px solid white;
    border-radius: 15px;
    width: 85%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: white;
    height: 40px;
    align-items: center;
    justify-content: center;
}

.nft-detail .transferirNft {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    margin: auto 0;
}


.input-address{
    background-color: transparent;
    border: none !important;
    width: 85%;
    color: white; 
    padding: 0 10px 0 0px;
}

.input-address:focus{
    color: white;
    outline: none;
}

.input-address::placeholder{
    color: rgb(171, 171, 171);
    border: none;
}

.icon-camera{
   width: 25px;
   height: 25px;
}

.description-wrapper-details{
    width: 85%;
    margin-top: 1rem;
}

.scanner-send{
    width: 320px !important;
}

.fullscreen-video{
    object-fit: fill !important;
}



.img-container > div {
    width: 100% !important;
    height: 100% !important;
}

.img-container > div > video{
    object-fit: fill !important;
    
}

.share-wrapper{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 1rem;
}

.nft-detail{
    margin-top: 2rem;
}

.nft-detail .center-flex-bottom .description-size {
    width: 60%;
}

@media (max-width: 1200px) {
    .nft-detail .center-flex-bottom .description-size{
        width: 80%;
    }
}

@media (max-width: 991px) {
    .nft-detail .center-flex-bottom .description-size{
        width: 100%;
    }
}


@media (min-width: 768px) {
    .title-wrapper-desktop{
        display: flex !important;
        flex-direction: column;
        width: 100%;
        justify-content: space-around;
        margin-bottom: 0.5rem;
        align-items: flex-start;
    }

    .nftImage{
        width: 100% !important;
        max-height: 100%;
    }
}

@media (max-width: 767px) {
    .center-flex-bottom{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .nftImage{
        width: 100% !important;
        max-height: 50vh;
    }

    .share-wrapper {
        width: 85%;
    }

    .transferirNft {
        width: 85%;
    }

    .title-wrapper{
        display: flex !important;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
        margin-bottom: 0.5rem;
    }
}