.mis-nft .col {
    text-align: center;
    margin: 20px auto;
}

.row-cards{
    margin-top: 1rem !important;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
}

.mis-nft .col img {
    min-height: 150px;
    min-width: 100%;
    max-height: 150px;
}

.img-nft{
    width: 100%;
}

.div-nfts{
    border: 1px solid black;
    border-radius: 15px;
    overflow: hidden;
    background-color: #2A0173;
    margin: 10px 1.5rem;
    box-shadow: 0 3px 3px 1px rgb(0, 0, 0, 0.4);
}

.nft-name{
    text-align: center;
    width: 100%;
    margin: 0.5rem 0rem;
    font-weight: 700;
}

.MuiPagination-root{
    color: white !important;
    text-align: center;
    display: flex;
    justify-content: center;
}


.MuiButtonBase-root{
    color: white !important;
}

@media only screen and (orientation:landscape) and (min-width:768px) {

    .row-cards{
        margin-top: 3rem !important;
        margin-left: 0.4rem;
        margin-right: 0.4rem;
        margin-bottom: 1rem !important;
    }
  }