.dtc-navigation {
  background-color: white !important;
}

/* Individual item */
.bm-item {
  display: inline-block;
  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: relative;
  width: 36px;
  height: 36px !important;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
  height: 14% !important;
  width: 90%;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #031B48 !important;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.bm-menu-wrap {
  position: absolute;
  left: inherit;
  z-index: 1100;
  width: 350px;
  top: 0px;
  height: 100%;
  transition: all 0s ease 0s;
  right: 0px;
}

.logo label {
  cursor: pointer;
}

.burger-pwa {
  display: flex;
  justify-content: flex-end;
  align-items: center;

}

.logo label {
  cursor: pointer;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  width: 300px;
  padding: 0.5rem 0 ;
}

.header-desktop a,
.header-desktop label {
  margin: 0 0.9rem;
  color: #002F7C;
  font-weight: 500;
}

.dropdown-menu a {
  margin: 0;
}

/* .dropdown-menu a:hover {
    margin: 0;
    padding: 0;
  } */

.header-desktop a {
  height: inherit;
  display: flex;
  align-items: center;
}

.header-desktop label {
  padding: 5px 15px;
  font-size: 1rem;
}

.header-desktop label:hover {
  cursor: pointer;
}

.header-desktop a.menu-item:hover,
.header-desktop a.dropdown-toggle:hover {
  height: inherit;
  display: flex;
  align-items: center;
  border-bottom: 6px solid #002F7C;
  /* border-top-left-radius: 50px 6px; */
  /* border-top-right-radius: 50px 6px; */
  border-radius: 6px;
  color: #002F7C !important;
  padding-top: 6px;
}

.header-desktop label.send-button {
  background-color: #002F7C;
  color: white;
  border-radius: 20px;
}

.header-desktop label.send-button:hover {
  background-color:#b0c5e6 ;
  color: #002F7C;
}

.dropdown-item.active, .dropdown-item:active{
  background-color: transparent;
  color: #002F7C;
}

.nav-link:focus, .nav-link:hover{
  color: #002F7C !important;
}

.burger-pwa {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-item.dropdown{
  height: inherit;
}

@media (max-width: 1200px) {

  .header-desktop a,
  .header-desktop label {
    margin: 0 0.4rem;
    text-align: center;
  }
}


@media (min-width: 992px) {
  .header-desktop {
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
    height: 80px;
  }
}


@media (max-width: 575px) {
  .logo img {
    width: 260px;
    padding: 1rem 0;
  }
}

@media (max-width: 380px) {
  .logo img {
    width: 200px;
  }
}