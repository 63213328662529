.container-landing{
    max-width: 80%;
    margin-bottom: 2rem;
}

.icon-text{
    color: #002F7C;
    font-weight: bold;
    text-align: center;
}

.icon-text-info{
    font-size: 12px;
    color: #07409d;
}

.icon-wrapper{
    background-color: #002F7C;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin-bottom: 1rem;
}

.icon-landing{
    width: 4rem;
    height: 4rem;
}

.purple-back{
    background-color: #11015C;
    display: flex;
    padding: 2rem 0rem;
    justify-content: space-evenly;
}

.white-text{
    color: #F1FAEE;
    font-size: 20px;
    font-weight: 600;
}

.btn-login {
    
    background-color: #0E56CC;
    color: white;
    font-size: 16px;
    padding: 6px 60px ;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }

.log-button:hover {
box-shadow: none;
}

.landing-share{
    width: 100% !important;
}

@media only screen and (min-width: 768px) {
    /* For desktop: */
    .white-text{
        font-size: 36px;
        font-weight: 600;
        
    }

    .render-login{
        max-height: 60vh;
    }
    .purple-back{
        background-color: #11015C;
        display: flex;
        padding: 2rem 10rem;
        justify-content: space-evenly;
    }
  }