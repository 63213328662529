.chip-container {
    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 1rem;
    min-width: 7rem;
    border: 1px solid white;
    margin-inline: auto;
}
.chip-title {

    font-size: 14px !important;
    color: white;

}

.chip-value{
    font-size: 20px !important;
    font-weight: bold !important;
    color: #E9E9E9;
    text-align:center;
}