.Box{
    width: 180px;
    height: 180px;
    background: linear-gradient(180deg, rgba(63,27,131,1) 35%, rgba(35,14,106,1) 90%);
    border-radius: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 17px rgb(0 0 0 / 37%);
}

.Box:hover{
    transform: scale(1.04);
    -moz-transform: scale(1.04);
    -webkit-transform: scale(1.04);
    transition: all .5s;
    cursor: pointer;
}

.Box label {
    font-weight: 600;
    margin-top: 10px;
}

#page-wrap{
    min-height: 54px;
    display: flex;
    align-items: center;
    padding: 10px 0;
}

.div-icons{
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #002F7C;
    width: 100%;
    border-top: 1px solid #266CDF;
}

.AdressAndLogos .fa-regular.fa-clone{
    background: #cb37df;
}

.AdressAndLogos .fa-solid.fa-share-nodes{
    background: #874af9;
}

.AdressAndLogos .fa-solid.fa-share-nodes:hover,
.AdressAndLogos .fa-regular.fa-clone:hover{
    background: #80808059;
    cursor: pointer;
}

.AdressAndLogos label{
    color: #b5b5b5;
    font-weight: 600;
}

.AdressAndLogos label:hover{
    text-decoration: underline;
    cursor: pointer;
}

.AdressAndLogos i{
    padding: 6px;
    border-radius: 50%;
    font-size: 15px;
    margin: 0 5px;
}

.AdressAndLogos div:nth-child(1){
    display: flex;
    align-items: center;
    align-content: center;
}

.balance-text{
    font-family: 'Catamaran', sans-serif;
    font-weight: 500;
    color: #A5A5A5;
}

.address-text{
    font-family: 'Catamaran', sans-serif;
    font-weight: 500;
    color: #F1FAEE;
}

.img-box-row .col-md-4{
    margin: 1rem 0;
    text-align: center;
}

.img-box-row .col-md-4 img:hover{
    transform: scale(1.1);
    -moz-transform: scale(1.04);
    /* -webkit-transform: scale(1.04); */
    transition: all .5s;
    cursor: pointer;
}


@media (max-width: 463px) {
    .AdressAndLogos label{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 223px;
    }
    .Box {
        width: 150px;
        height: 150px;
    }
    .Box label {
        font-size: 0.9rem;
    }
}

@media (max-width: 348px) {
    .Box {
        width: 100px;
        height: 100px;
    }
    .Box label {
        font-size: 0.8rem;
    }
    .correccion .col {
        padding: 0;
        justify-content: space-evenly;
    }
}
