.freeze-footer{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 100;
}

.footer-opciones{
    min-height: 70px;
    background: #031B48 !important;
    border-top: #0E56CC 5px solid;
    display: flex;
}

.row{
    width: 100%;
}

.d-flex-center-space-evenly{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.footer-opciones > div:last-child {
    border-right: 0px solid white !important;
    display: flex;
    font-size: 0.8rem;
}

.footer-opciones p{
    font-size: 13px;
    margin-bottom: 0rem;
}

.footer-opciones i{
    font-size: 20px;
}

.footer-upload{
    cursor: pointer;
}
.footer-upload:hover{
    cursor: pointer;
}

@media (max-width: 991px) {
    .footer-opciones{
        border-top: #0E56CC 2px solid;
        min-height: 55px;
        justify-content: space-around;
        flex-direction: row;
        align-items: center;
    }

    .footer-opciones div label,
    .footer-opciones a div{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}