/* .fa-qrcode{
    font-size: 200px;
} */

.receive div {
    text-align: center;
}

.qr-code {
    text-align: center;
    padding: 20px;
    width: fit-content;
    margin: auto;
}

.address {
    word-break: break-all;
}

.address div{
    display: flex;
    justify-content: center;
    align-items: center;
}

.address div i {
    margin: 0 0 0 10px;
}

h4 {
    text-align: initial;
    width: 453px;
    margin: 30px auto 20px;
}

.address span:hover {
    text-decoration: underline;
}

.address span,
.address .fa-regular.fa-copy,
.address .fa-solid.fa-share-nodes{
    cursor: pointer;
}

.address i {
    padding: 6px;
    border-radius: 50%;
    font-size: 15px;
    margin: 0 5px;
}

.address .fa-solid.fa-share-nodes,
.address .fa-regular.fa-copy{
    padding: 5px;
    background: none;
}

.address .fa-solid.fa-share-nodes {
    background: #874af9;
}

.address .fa-regular.fa-copy {
    background: #cb37df;
}

.address .fa-solid.fa-share-nodes:hover,
.address .fa-regular.fa-copy:hover{
    background: #80808059;
    border-radius: 50%;
}
@media (max-width: 667px) {
    h4 {
        width: 100%;
        margin: 10px auto;
    }
    .address div{
        margin: 0 0 20px;
    }

    .receive div {
        text-align: center;
        max-width: 296px;
        margin: auto;
    }
}

