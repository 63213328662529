/* Container needed to position the button. Adjust the width as needed */

  
  /* Make the image responsive */
  .container-login {
    width: 100%;
    height: auto;
  }
  
  .login-first-part{
    background-color: white;
    height: 40vh;
  }

  .container-login img{
    width: 100%;
    max-height: 60vh;
    object-fit: cover;
    object-position: 0% 0%;
  }

  .img-login {
    max-width: 400px;
    width: 100%;
  }
  
  /* Style the button and place it in the middle of the container/image */
  .container-login .btn-login {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #0E56CC;
    color: white;
    font-size: 16px;
    padding: 6px 60px ;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }

  @media only screen and (min-width: 768px) {
    /* For desktop: */
    .container-login {
      text-align: center;
      width: 100%;
      background-color: white;
  }
  .container-login img{
    width: initial;

  }

  .login-big{
    position: absolute !important;
    top: 70% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    -ms-transform: translate(-50%, -50%) !important;
    background-color: #0E56CC;
    color: white;
    font-size: 24px !important;
    padding: 18px 85px !important;
    font-weight: 700 !important;
  }
  
  .login-big:hover{
    background-color: #07347c;
    padding: 1rem;
    transition: background-color 2s;
  }
  }