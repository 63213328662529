.add-contact{
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.add-contact>i {
    background: #0E56CC;
    width: 60px;
    font-weight: 600;
    padding: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    font-size: 30px;
}

.input-group-text{
    background: transparent;
    cursor: pointer;
}

.input-group span{
    /* BORDER-left: 0PX SOLID WHITE;
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important; */
    position: absolute;
    left: 432px;
    top: 1px;
    z-index: 20;
    border: 0px;
}

.was-validated .input-group span {
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.input-group>.form-control, .input-group>.form-select {
    border-bottom-right-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
}

.add-contact .input-group #address{
    padding-right: 50px;
}

@media (max-width: 510px) {
    .input-group span {
        left: 89%;
    }
}

@media (max-width: 410px) {
    .input-group span {
        left: 85%;
    }
}