.wrapper-qr{
    height:100vh;
}

.scannerFullScreen{
    min-height: 100%;
}

.scannerFullScreen > div {
    min-height: 100% !important;
    height: 100vh !important;
    padding-top: 0px !important;
}

.scannerFullScreen > div > video{
    min-height: 100% !important;
    width: auto !important;
}