
.accept-terms-button {
    border-radius: 100rem;
    padding: 1rem;
    padding: .5rem 3rem;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: solid 3px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #ff48fa,#78e4ff, #59bc1c) !important;
    background-origin: border-box;
    background-clip: content-box, border-box;
/*     box-shadow: 2px 1000px 1px #13054C inset;
 */    font-weight: 500;
    font-family: 'Roboto';
    font-size: 1.5rem;
}

.first-img{
    width: auto;
    height: 60vh;
}

.accept-terms-button:disabled{
    width: 100%;
    border-radius: 100rem !important;
    padding: 0.6rem 3.1rem;
    background: linear-gradient(270deg, #cecece 0%, #787878 22.97%, #303030 51.93%, #767676 83.85%, #ffffff 100%) !important;
    box-shadow: 2px 1000px 1px #7b7b7b inset !important;
    font-weight: 650 !important;
    font-size: 20px !important;
    font-family: 'Roboto' !important;
    margin-bottom: 5rem;
}

.li-login{
    margin-bottom: 5px;
}

@media (max-width: 991px) {
    .first-img {
        width: auto;
        height: 48vh;
    }
}

@media (max-width: 575px) {
    .first-img {
        width: auto;
        height: 42vh;
    }
}

@media (max-width: 350px) {
    .first-img {
        width: auto;
        height: 35vh;
    }

    .li-login {
        margin-bottom: 0px;
    }
}