.modal-sendButton > div {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
}

@media only screen and (min-width: 768px) {
    /* For desktop: */
    .modal-sendButton > div {
        margin-right: auto !important;
        margin-left: auto !important;
    }
  }
