.reload-button{
    border-radius: 100rem;
    /* padding: 1rem; */
    padding: 0.5rem 1rem;
    box-shadow: 0 0 6px 0 rgb(157 96 212 / 50%);
    border: solid 3px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #ff48fa,#78e4ff, #59bc1c);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #13054c inset;
    font-weight: 500;
    font-family: 'Roboto';
    /* font-size: 1.5rem; */
    color: white;
}