.form-control {
    background: transparent !important;
    color:white;
}

.form-control:focus {
    color:white;
}

iframe {
    display: none;
}

i {
    color: white;
    font-size: 24px;
}

.button-login{
    background: transparent;
    border: none;
}

.button-login span{
    color:white;
}

.button-login span:hover{
    text-decoration: underline;
}

.button-login:hover{
    cursor: pointer;
}

.other-login{
    display: flex;
    margin: 5px 0 15px;
}

.other-login i,
.other-login span{
    margin: 0 5px;
}

.other-login span{
    color: white;
    font-weight: 500;
}

.other-login i:hover{
    cursor: pointer;
}

.other-login span:hover,
.remember-forgot span:hover{
    text-decoration: underline;
    cursor: pointer;
}

.form-width {
    width: 85%;
    margin: auto;
}



.remember-forgot{
    display: flex;
    justify-content: space-between;
    margin: 0.2rem 0 1rem;
    color: white;
}

.btn-claim{
    background-color: #F9F9F9;
    border: none;
    color: #002F7C;
    font-weight: 700;
    border-radius: 30px;
    height: 3rem;
}

@media (max-width: 380px) {
    .tamaño-imagenes img{
        width: 200px;
    }
}

@media (max-height: 485px) {
    .tamaño-imagenes img{
        width: 150px;
    }
}