.ReactModal__Overlay{
    z-index: 999;
    background-color: rgb(0 0 0 / 75%) !important;
    padding-top: 1rem;
    display:flex;
    align-items: center;
}

.ReactModal__Content h2{
    color: #0E56CC !important;
    text-align: center;
    margin: 1rem 0;
    font-weight: bold;
    font-size: 24px;
}

.ReactModal__Content p{
    color: #505050;
    text-align: center;
    font-weight: 600;
    font-size: 1.2rem;
    padding: 0rem;
    margin-bottom: 0;
}


.ReactModal__Content .buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 2rem;
    
}

.cancel-button{

    border-radius: 5px;
    font-weight: 500;
    padding: 0.5rem 1.5rem;
    font-style: italic;
    font-size: 1.2rem;
    background: white;
    color: #0E56CC;
    border:1px solid #0E56CC;
    font-style: normal;
} 
.accept-button{
    border-radius: 5px;
    font-weight: 500;
    font-style: italic;
    padding: 0.5rem 1.5rem;
    font-size: 1.2rem;
    background: #0E56CC;
    color: white;
    font-style: normal;
    border: 0px solid !important;
} 

.done-button{
    border-radius: 100rem;
    font-weight: 500;
    width: 80%;
    padding: 0.7rem 0.7rem;
    font-size: 1.2rem;
    background: #0E56CC;
    color: white;
    font-style: normal;
    border: 0px solid !important;
}

.steps-button{
    border-radius: 10px;
    font-weight: 500;
    padding: 0.4rem 1rem;
    font-size: 1rem;
    background: white;
    color: #0E56CC;
    border:1px solid #0E56CC;
    font-style: normal;
}

.steps-button:disabled{
    border-radius: 10px;
    font-weight: 500;
    padding: 0.4rem 1rem;
    font-size: 1rem;
    background: #6999e8;
    color: white;    
}

/* .MuiStepIcon-root.Mui-active{
    color: #7863E1 !important; 
} */

.ReactModal__Content{
    border: 1px solid #797979 !important;
    border-radius: 20px !important;
    width: 450px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.ReactModal__Content .send-button{
    box-shadow: 2px 1000px 1px #ffffff inset;
    color: #0E56CC;
}

.ReactModal__Content .send-button,
.ReactModal__Content .accept-btn {
    text-transform: uppercase;
    font-style: initial;
    font-weight: 400;
    margin: 5px 0;
}

.ReactModal__Content i {
    color: #0E56CC;
    font-size: 3rem;
}

.ReactModal__Overlay--before-close{
    pointer-events: none
}

@media (max-width: 600px) {
    .ReactModal__Content{
        border: 1px solid #797979 !important;
        border-radius: 10px !important;
        width: 300px;
    }
}