
.max-button-col label{
    width: 100%;
}

.max-button-col input{
    display: inline-block;
    width: 68%;
}

.max-button{
    display: inline-block;
    width: 27%;
    margin: 0 0 0 5px;
    position: relative;
    top: -2px;
    background: transparent;
    border: 0px;
}

.max-button:hover {
    text-decoration: underline;
    background: transparent;
}
.form-select{
    margin: auto;
    background: #ffffff00;
    color: white;
}

.form-select option{
    color: #212529;
}   

.form-select.is-valid, .was-validated .form-select:valid {
    background-image: none !important   ;
}

.form-send input{
    border-radius: 0.375rem !important;
}

.form-send select{
    background-image: none !important;
}

h3 span{
    display: inline;
    text-decoration: none;
    background-image: linear-gradient(transparent, transparent),linear-gradient(transparent, transparent),linear-gradient(270deg, #B0F65B 0%, #01C6C5 22.97%, #4A7FF3 51.93%, #8171F5 83.85%, #B66DF3 100%);
    background-repeat: no-repeat;
    background-position: 120%, 122%, 0 130%;
    background-size: 100% 10px;
    padding-bottom: 3px;
}


.max-button:active:focus{
    box-shadow: 0 0 0 0;
}

.qr-code {
    text-align: center;
}


@media (max-width: 500px) {
    .form-select {
        margin: auto;
        margin-right: calc(var(--bs-gutter-x) * .5);
    }

    
    .max-button{
        padding: 5px 2px;
    }

    
}